import React from "react";
import Image from "next/image";
function DataStatisticSection({ arrDataPhysicalStat, arrDataCollectionDates, listDataStatistic }) {

  return (
    <>
      <a className="anchor-target" id="statistic-section"></a>
      {/* statistic data section */}
      <div id="statistic" className="py-5">
        {/* <div className="m-3">&nbsp;</div> */}
        <div className="container">
          <h4
            className="news-prime-title text-white"
            data-aos="zoom-in"
            data-aos-duration={500}
          >
            <i className="fa fa-lg fa-light fa-chart-simple text-warning"></i>{" "}
            Statistik
          </h4>
          <div className="mb-3">&nbsp;</div>
          {/* physical data */}
          <h4 className="align-content-center text-center fw-semibold text-white">
            Data Fisik
          </h4>

          <p className="ms-2 fw-semibold text-white">
            {/* data[0] for data fisik */}
            Diperbarui pada: {arrDataCollectionDates.data[0].attributes.last_updated_in_text} 
          </p>
          <div className="row">
            {arrDataPhysicalStat.map((physicData) => (
              <div key={physicData.id} className="col-12 col-xs-12 col-sm-12 col-md-6 col-xl-4 ">
                <div className="statistic-card ps-4 pe-2 py-2 rounded d-flex flex-column justify-content-between shadow-lg">
                  <div
                    className="text-end"
                    data-aos="zoom-in"
                    data-aos-duration={500}
                  >
                    <Image
                      src={
                        "/assets/PortalAsset/statistic-data/" +
                        physicData.attributes.uom.toLowerCase() +
                        ".png"
                      }
                      className="ms-3 align-self-start"
                      width={30}
                      height={30}
                      alt="icondb"
                    />
                  </div>
                  <div
                    className="d-flex h-100 align-items-center"
                    style={{ marginTop: "-1.5rem" }}
                  >
                    <div
                      className="fs-6 fw-semibold statistic-kind-data"
                      style={{ width: "70%" }}
                      data-aos="zoom-in"
                      data-aos-duration={600}
                    >
                      {physicData.attributes.kind}
                    </div>
                    <div
                      className="pe-4"
                      style={{ width: "30%" }}
                      data-aos="zoom-in"
                      data-aos-duration={650}
                    >
                      <div className="fs-4 fw-semibold text-end statistic-card-value">
                        {physicData.attributes.value_total.toLocaleString(
                          "id-ID"
                        )}
                      </div>
                      <div className="text-end fst-italic">
                        {physicData.attributes.uom}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Digital Data */}
          <div className="m-3">&nbsp;</div>
          <hr className="text-white" />
          <h4 className="align-content-center text-center text-white fw-semibold">
            Data Digital
          </h4>
          <p className="ms-2 fw-semibold text-white">
            Realtime Update 
          </p>

          <div className="row">
            {listDataStatistic.map((digitalData) => (
              <div key={digitalData.noIndex} className="col-12 col-xs-12 col-sm-12 col-md-6 col-xl-4 ">
                <div className="statistic-card ps-4 pe-2 py-2 rounded d-flex flex-column justify-content-between shadow-lg">
                  <div
                    className="text-end"
                    data-aos="zoom-in"
                    data-aos-duration={500}
                  >
                    <Image
                      src={
                        "/assets/PortalAsset/statistic-data/records.png"
                      }
                      className="ms-3 align-self-start"
                      width={30}
                      height={30}
                      alt="icondb"
                    />
                  </div>
                  <div
                    className="d-flex h-100 align-items-center"
                    style={{ marginTop: "-1.5rem" }}
                  >
                    <div
                      className="fs-6 fw-semibold statistic-kind-data"
                      style={{ width: "70%" }}
                      data-aos="zoom-in"
                      data-aos-duration={600}
                    >{digitalData.colName}</div>
                    <div
                      className="pe-4"
                      style={{ width: "30%" }}
                      data-aos="zoom-in"
                      data-aos-duration={650}
                    >
                      <div className="fs-4 fw-semibold text-end statistic-card-value">
                        {digitalData.numFound.toLocaleString(
                          "id-ID"
                        )}
                      </div>
                      <div className="text-end fst-italic">
                        records
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* chart statistic */}
          <div className="m-3">&nbsp;</div>
          <hr className="text-white" />
          <h4 className="align-content-center text-center text-white fw-semibold py-2">
            Informasi Layanan Data <br/><span className="fst-italic fw-light fs-6">(dalam satuan GB)</span>
          </h4>
          
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="w-100 bg-white rounded p-1" id="chartContainer">
              <canvas
                id="myChart"
                className="px-5 py-3"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
          {/* end of chart statistic */}
        </div>
      </div>
    </>
  );
}

export default DataStatisticSection;
