import React from 'react'

function LinkScriptImporter() {
  return (
    <>
      <link rel="stylesheet" href="css_landing/style.css" />
      <script src="js_landing/chartLib.js"> </script>
      <link href="css_landing/aos-2-3-1.css" rel="stylesheet"></link>
      <script src="js_landing/aos.js"></script>
      {/* gis head */}
      <link rel="stylesheet" href="css_landing/fontawesome-6.4.0.css" />
      <link rel="stylesheet" href="js_landing/js_gis/js/leaflet.css" crossOrigin="" />
      <link rel="stylesheet" href="js_landing/js_gis/js/leaflet.zoomhome.css" />
      <link rel="stylesheet" href="//unpkg.com/leaflet-gesture-handling/dist/leaflet-gesture-handling.min.css" type="text/css" />
      <script src="js_landing/js_gis/js/leaflet.js" crossOrigin=""></script>
      <script src="js_landing/js_gis/js/esri-leaflet.js"></script>
      {/* <script src="js_landing/js_gis/js/esri-leaflet-vector.js" crossOrigin=""></script> */}
      <link
        rel="stylesheet"
        href="js_landing/js_gis/js/esri-leaflet-geocoder.css"
        crossOrigin="" 
      />
      <script
        src="js_landing/js_gis/js/esri-leaflet-geocoder.js"
        crossOrigin=""
      ></script>
      <script src="js_landing/js_gis/js/leaflet.zoomhome.js" crossOrigin=""></script>
      <script
        src="js_landing/js_gis/js/esri-leaflet-renderers-debug.js"
        crossOrigin=""
      ></script>
      <script
        src="js_landing/js_gis/js/esri-leaflet-legend-compat-src.js"
        crossOrigin=""
      ></script>
      <script src="//unpkg.com/leaflet-gesture-handling"></script>
      <script src="js_landing/chartBuilder.js" />
    </>
  )
}

export default LinkScriptImporter