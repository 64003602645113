import React from 'react'
import Image from 'next/image'

function CarouselSection({arrCarouselItemImg, sliderLinkHref} ) {
  return (
    <>
        {/* carousel section */}
        <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
          <ol className="carousel-indicators">
            
            {arrCarouselItemImg.map((item, index) => {
              if(index === 0){
              return (<li
                key={index}
                data-bs-target="#carouselId"
                data-bs-slide-to={0}
                className="active"
                aria-current="true"
                aria-label="First slide"
                />)
              }else{
              return (<li
              data-bs-target="#carouselId"
              data-bs-slide-to={index}
              key={index}
              aria-label={index}
              />)
              }
            })}
           
          </ol>
          
          <div className="carousel-inner" id="carouselInnerSet" role="listbox">
            {arrCarouselItemImg.map((item, index) => {
              if(index === 0){
              return (
              <div key={item} className="carousel-item active" role="option" aria-selected="true">
                <a href={sliderLinkHref[index]===null? "#" : sliderLinkHref[index]} target={sliderLinkHref[0]===null? "": "_blank"}>
                <Image 
                  width={0}
                  height={50}
                  src={item}
                  className="w-100 d-block"
                  priority={100}
                  sizes="100vw"
                  alt="First slide"
                />
                </a>
              </div>)
              }else{
              return (
                <div key={item} className="carousel-item" role="option" aria-selected="false">
                <a href={sliderLinkHref[index]===null? "#" : sliderLinkHref[index]} target={sliderLinkHref[1]===null? "": "_blank"}>
                <Image 
                    width={0} height={700}
                    src={item}
                    className="w-100 d-block"
                    priority={100}
                    sizes="100vw"
                    alt="Second slide"
                  />
                  </a>
                </div>
              )
              }
            })}
            
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {/* end of carousel section */}
      </>
  )
}

export default CarouselSection