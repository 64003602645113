import React from "react";
import Image from "next/image";
function InterlinkSection({ arrDataSplashText , newComingDataLinks}) {
  // console.log(newComingDataLinks)
  const isLeftSide = (val) =>
    val.attributes.Side.toLowerCase() === "penerimaan_data_baru";
  const isRightSide = (val) =>
    val.attributes.Side.toLowerCase() === "data_digital_baru";
  const isNextComing = (val) =>
    val.attributes.Side.toLowerCase() === "next_coming";
  let SplashTextLeftShow = "";
  let SplashTextRightShow = "";
  let SplashTextNextComingShow = "";
  let dataSplashLeft = [];
  let dataSplashRight = [];
  let dataSplashNextComing = [];
  try {
    const filteredLeft = arrDataSplashText.filter(isLeftSide);
    dataSplashLeft = filteredLeft.map((list) => list.attributes.Description);
    //const joinedSplashTextLeft = filteredLeft.map((list) => list.attributes.Description).join(" | ")

    const filteredRight = arrDataSplashText.filter(isRightSide);
    //const joinedSplashTextRight = filteredRight.map((list) => list.attributes.Description).join(" | \t")
    dataSplashRight = filteredRight.map((list) => list.attributes.Description);

    const filteredNextComing = arrDataSplashText.filter(isNextComing);
    //const joinedSplashTextRight = filteredRight.map((list) => list.attributes.Description).join(" | \t")
    dataSplashNextComing = filteredNextComing.map(
      (list) => list.attributes.Description
    );

    // SplashTextLeftShow = joinedSplashTextLeft
    // SplashTextRightShow = joinedSplashTextRight
    // SplashTextNextComingShow = joinedSplashTextRight
  } catch (error) {
    SplashTextLeftShow = "";
    SplashTextLeftShow = "";
    dataSplashNextComing = "";
  }
  // console.log(dataSplashLeft)

  return (
    <>
      {/* splash text info and interlink section */}
      <div className="py-4" id="splash-interlink-section">
        <div className="container">
          <div
            className="d-flex flex-wrap justify-content-around"
            id="splash-text"
          >
            <div
              className="splash-text-item w-50 d-flex align-items-center"
            >
              <div className="btn btn-warning w-25" style={{cursor:"default"}}>Penerimaan Data Baru</div>
              <span className="text-white text-wrap" style={{width:"100%", marginRight:"1rem", padding:"1rem 0"}}>
                <marquee
                  id="splashLeft"
                  behavior="scroll"
                  scrollamount="5"
                  width="100%"
                  className="d-flex align-items-center"
                  direction="left"
                >
                  {dataSplashLeft.map((dat) => (
                    <span key={dat}>
                      <p className="d-inline fw-normal">&nbsp; &nbsp; &nbsp; &nbsp; {dat} &nbsp; &nbsp; &nbsp; &nbsp;</p>
                    </span>
                  ))}
                </marquee>
              </span>
            </div>
            <div
              className="splash-text-item w-50 d-flex align-items-center"
            >
              <div className="btn btn-warning" style={{cursor:"default"}}>Data Digital Baru</div>
              <span className="text-white text-wrap" style={{width:"100%", marginRight:"1rem"}}>
                <marquee
                  className="d-flex align-items-center"
                  behavior="scroll"
                  scrollamount="5"
                  width="100%"
                  direction="left"
                >
                  {dataSplashRight.map((dat) => (
                    <span key={dat}>
                       <p className="d-inline fw-normal">&nbsp; &nbsp; &nbsp; &nbsp; {dat} &nbsp; &nbsp; &nbsp; &nbsp;</p>
                    </span>
                  ))}
                </marquee>
              </span>
            </div>
          </div>
          
          
          <div className="my-5 container rounded relative w-100 rounded" id="new_coming_section" >
            <Image 
              className="rounded shadow-lg w-100 h-100" 
              src={newComingDataLinks[0]} 
              width={0} 
              height={100} 
              priority={100}
              
              sizes="100vw" 
              alt="new coming"/>
          </div>
        
        </div>
        <div
          className="d-flex flex-wrap align-content-center justify-content-between h-100 gap-4 container"
          id="interlink"
        >
          <a href="https://www.esdm.go.id/wkmigas/" target="_blank">
            <div
              className="border overflow-hidden border-2 p-3 rounded d-flex flex-column align-items-center justify-content-center gap-2 interlink-card"
              data-aos="zoom-in-down"
              data-aos-duration={1500}
            >
              <img
                src="/assets/PortalAsset/inter-link/classroom.png"
                style={{ width: 127, height: 127 }}
                alt="interlink-caption"
              />
              <h3 className="text-white">E-Lelang WK</h3>
            </div>
          </a>
          <a href="https://geologi.esdm.go.id/geomap" target="_blank">
            <div
              className="border overflow-hidden border-2 p-3 rounded d-flex flex-column align-items-center justify-content-center gap-2 interlink-card"
              data-aos="zoom-in-down"
              data-aos-duration={2000}
            >
              <img
                src="/assets/PortalAsset/inter-link/globe.png"
                style={{ width: 127, height: 127 }}
                alt="interlink-caption"
              />
              <h3 className="text-white">Geo-Migas PSG</h3>
            </div>
          </a>
          <a href="https://www.skkmigas.go.id/" target="_blank">
            <div
              className="border overflow-hidden border-2 p-3 rounded d-flex flex-column align-items-center justify-content-center gap-2 interlink-card"
              data-aos="zoom-in-down"
              data-aos-duration={2500}
            >
              <img
                src="/assets/PortalAsset/inter-link/oilpump.png"
                style={{ width: 127, height: 127 }}
                alt="interlink-caption"
              />
              <h3 className="text-white">Investment Opportunity</h3>
            </div>
          </a>
          <a href="https://geoportal.esdm.go.id/" target="_blank">
            <div
              className="border overflow-hidden border-2 p-3 rounded d-flex flex-column align-items-center justify-content-center gap-2 interlink-card"
              data-aos="zoom-in-down"
              data-aos-duration={3000}
            >
              <img
                src="/assets/PortalAsset/inter-link/mapmarker.png"
                style={{ width: 127, height: 127 }}
                alt="interlink-caption"
              />
              <h3 className="text-white">Geo-Portal</h3>
            </div>
          </a>
        </div>
      </div>
      {/* end of splash text info and interlink section */}
    </>
  );
}

export default InterlinkSection;
